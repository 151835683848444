<template>
    <v-row id="header-cards" class="ma-0 pa-0" justify="space-between">
        <v-col cols="4" class="py-0 pl-0">
            <div class="d-flex w-full h-full">
                <v-card elevation="2" v-if="model.SHOW_CHALLENGE === 1" tile width="100%" height="100%">
                    <v-card-title class="text-uppercase justify-center font-weight-bold">
                        {{currentTargetModel && currentTargetModel.deleted_at ? 'Expired' : 'Current'}} Target</v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title class="text-h4 primary--text justify-center text-break font-weight-bold pb-0">
                        <div class="skeleton-list-item w-full" v-if="valueChanged">
                            <v-skeleton-loader height="30px" :loading="true" type="list-item"></v-skeleton-loader>
                        </div>
                        <span v-if="!valueChanged && currentTargetModel && currentTargetModel.type && ['C', 'U', 'M'].includes(currentTargetModel.type)"
                            v-currency="{value:currentTargetModel.field_2 || 0, options:{maximumFractionDigits:0} }">
                            {{ `${currentTargetModel.field_1} at` }}
                        </span>
                        <div v-if="currentTargetModel && currentTargetModel.type && ['G'].includes(currentTargetModel.type)">
                            {{ currentTargetModel.field_1 }} gifts
                        </div>
                        <span  v-if="currentTargetModel && currentTargetModel.type && ['DM'].includes(currentTargetModel.type)">
                            <div class="d-flex justify-center">Matched</div>
                            <div class="skeleton-list-item w-full" v-if="valueChanged">
                                <v-skeleton-loader height="30px" :loading="true" type="list-item"></v-skeleton-loader>
                             </div>
                            <span v-if="!valueChanged" v-currency="{value:currentTargetModel.field_1 || 0, options:{maximumFractionDigits:0} }">Until</span>
                        </span>
                        <div class="skeleton-list-item w-full" v-if="valueChanged  && currentTargetModel && currentTargetModel.type && ['GD'].includes(currentTargetModel.type)">
                            <v-skeleton-loader height="30px" :loading="true" type="list-item"></v-skeleton-loader>
                        </div>
                        <span v-if="!valueChanged && currentTargetModel && currentTargetModel.type && ['GD'].includes(currentTargetModel.type)"
                            v-currency="{value:currentTargetModel.field_1 || 0, options:{maximumFractionDigits:0} }"/>
                    </v-card-title>
                    <v-card-title class="justify-center font-weight-bold pa-3 text-break">
                        by <span class="ml-2" v-if="currentTargetModel && currentTargetModel.deleted_at"> {{ currentTargetModel.deleted_at }}</span>
                        <span class="ml-2" v-if="currentTargetModel && !currentTargetModel.deleted_at"> {{currentTargetModel.end_time | amPmTime}}</span>
                        <span class="ml-2" v-if="currentTargetModel && currentTargetModel.type && ['GD'].includes(currentTargetModel.type)">for {{currentTargetModel.field_2}}</span>
                    </v-card-title>
                </v-card>
                <v-card elevation="2" tile width="100%" height="100%">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pa-0">
                            <v-card-title class="text-uppercase justify-center font-weight-bold px-0">Received</v-card-title>
                            <v-divider class="mx-4"></v-divider>
                            <v-skeleton-loader v-if="latestTargetLoading"
                                v-bind="{class: 'skeleteon-loader mt-3'}"
                                type="list-item"
                            ></v-skeleton-loader>
                            <v-card-title v-if="!latestTargetLoading" class="text-h4 primary--text justify-center font-weight-bold px-0">
                                <span class="mr-2" v-if="currentTargetModel && currentTargetModel.type && ['DM', 'GD'].includes(currentTargetModel.type)">$</span>
                                {{ currentTargetModel ? Number(currentTargetModel.raised) : 0}}
                            </v-card-title>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                            <v-card-title class="text-uppercase justify-center font-weight-bold px-0">Available</v-card-title>
                            <v-divider class="mx-4"></v-divider>
                            <v-skeleton-loader v-if="latestTargetLoading"
                                v-bind="{class: 'skeleteon-loader mt-3'}"
                                type="list-item"
                            ></v-skeleton-loader>
                            <v-card-title class="text-h4 primary--text justify-center font-weight-bold px-0" v-if="!latestTargetLoading">
                                <span class="mr-2" v-if="currentTargetModel && currentTargetModel.type && ['DM', 'GD'].includes(currentTargetModel.type)">$</span>
                                {{ currentTargetModel ? Number(currentTargetModel.remaining) : 0 }}
                            </v-card-title>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-col>
        <v-col class="py-0 pl-0">
            <v-card elevation="2" tile width="100%" height="100%">
                <v-card-title class="text-uppercase justify-center font-weight-bold">Challenge</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-skeleton-loader v-if="latestTargetLoading"
                    v-bind="{class: 'skeleteon-loader mt-3'}"
                    type="list-item"
                ></v-skeleton-loader>
                <v-card-title class="text-h4 primary--text justify-center font-weight-bold" v-if="!latestTargetLoading">
                    {{ secondsInMinutes(targetTime, true) }}
                </v-card-title>
            </v-card>
        </v-col>
        <v-col class="py-0 pl-0">
            <v-card elevation="2" tile width="100%" height="100%" :color="timerState === 1 ? 'danger' : 'white'">
                <v-card-title class="text-uppercase justify-center font-weight-bold"
                    :class="{'text-white': timerState === 1}">On-air</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-skeleton-loader v-if="prodGetOptLoading"
                    v-bind="{class: 'skeleteon-loader mt-3'}"
                    type="list-item"
                ></v-skeleton-loader>
                <v-card-title v-if="!prodGetOptLoading" class="text-h4 justify-center font-weight-bold"
                    :class="timerState === 1 ? 'text-white' : 'primary--text'">
                    {{ secondsInMinutes(onAirTime) }}
                </v-card-title>
            </v-card>
        </v-col>
        <v-col class="py-0" :class="$route.name === 'On Air' ? 'pl-0' : 'px-0'">
            <v-card elevation="2" tile width="100%" height="100%">
                <v-card-title class="text-uppercase justify-center text-sm-center font-weight-bold text-break">Public tally</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-skeleton-loader v-if="producerActcampaignLoading"
                    v-bind="{class: 'skeleteon-loader mt-3'}"
                    type="list-item"
                ></v-skeleton-loader>
                <v-card-title v-if="!producerActcampaignLoading" class="text-h4 primary--text justify-center font-weight-bold pb-0">
                    {{ producerActCampModel ? producerActCampModel.progress : 0 }}%
                </v-card-title>
                <v-skeleton-loader v-if="latestIncentiveLoading"
                    v-bind="{class: 'skeleteon-loader mt-3'}"
                    type="list-item"
                ></v-skeleton-loader>
                <v-card-title v-if="!latestIncentiveLoading && incModel && model.SHOW_INCENTIVE === 1"
                    class="primary--text justify-center text-sm-center font-weight-bold pa-3 text-break">
                    {{ `${incModel.sum_incentive_count} gifts for ${incModel.name}` }}
                </v-card-title>
            </v-card>
        </v-col>
        <v-col class="py-0 px-0" v-if="$route.name === 'On Air'">
            <v-card elevation="2" tile width="100%" height="100%">
                <v-row class="ma-0 h-full" align="center" justify="center">
                    <v-col cols="12">
                        <v-card-title class="text-h4 justify-center text-sm-center font-weight-bold text-break secondary--text">1300 777 899</v-card-title>
                        <a class="text-h5 pa-4 pt-0 d-flex justify-center align-center font-weight-bold text-break"
                            href="https://thelight.com.au/" target="_blank" >thelight.com.au</a>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import globalHelper from '@/helpers/global.helper';

import { CurrencyFormat } from "@/directives";

export default {
    directives: {
        'currency': CurrencyFormat,
    },
    data: (vm) => ({
        timerInterval:null,
        onAirTime: 0,
        timerState: null,
        targetTime: 0,
        targetTimer: null,
        model: {
            SHOW_INCENTIVE: 0,
            SHOW_CHALLENGE: 0,
        },
        incModel: {
            sum_incentive_count: 0,
            name: ''
        },
        valueChanged: false
    }),
    computed: {
        ...mapGetters(["latestTargetLoading", "currentTargetModel", "prodGetOptLoading", "onAirTimerModel",
            "producerActcampaignLoading", "producerActCampModel", "currentIncentive", "latestIncentiveLoading"]),
    },
    filters: {
        amPmTime: function(date) {
            return globalHelper.getMomentDatas('hh:mm A', date);
        }
    },
    watch: {
        timerState: function (newValue) {
            if (newValue === 1) {
                this.timerInterval = setInterval(() => {
                    this.onAirTime++;
                }, 1000);
            } else if (newValue === 0) {
                clearInterval(this.timerInterval);
            } else {
                this.onAirTime = 0;
                clearInterval(this.timerInterval);
            }
        },
        onAirTimerModel: function (newValue) {
            if(newValue) this.onairTimerUpdate(newValue);
        },
        currentTargetModel: function (newValue) {
            this.valueChanged = true;
            setTimeout(() => {
                this.valueChanged = false;
            }, 10);
            this.elapsedTargetUpdated();
        },
        currentIncentive: function(newValue) {
            if(newValue) this.incModel = newValue;
        }
    },
    created() {
        this.getLatestTarget();
        this.getInfoData();
        this.getActiveCampaign({with_variation: 1});
        this.getLatestIncentive({with_variation: 1}).then(resp => this.incModel = resp.data.data);
    },
    mounted() {
        this.wsOptActionUpdate();
        this.wsTargetUpdate();
    },
    methods: {
        ...mapActions(["getLatestTarget", "getOptionsData", "getActiveCampaign", "getLatestIncentive"]),

        secondsInMinutes(seconds, challenge=false) {
            return globalHelper.givenSecToTime(seconds, challenge);
        },
        getInfoData() {
            const reqData = {
                name: 'search',
                value: {
                    option_name: ['SHOW_CHALLENGE', 'SHOW_INCENTIVE', 'ON_AIR_TIMER'],
                }
            }
            this.getOptionsData(reqData).then((response) => {
                this.model = {...this.model, ...response.data.data};
                if (response.data.data.ON_AIR_TIMER) {
                    this.onairTimerUpdate(response.data.data.ON_AIR_TIMER);
                }
            });
        },
        startTarget() {
            this.targetTimer = setInterval(() => {
                if (this.targetTime === 0) this.resetTarget();
                else this.targetTime--;
            }, 1000);
        },
        resetTarget() {
            this.targetTime = 0;
            clearInterval(this.targetTimer);
        },
        elapsedTargetUpdated() {
            this.targetState = 0;
            if (this.currentTargetModel && !this.currentTargetModel.deleted_at) {
                const currentExist =  this.$helpers.getMomentDiff(this.currentTargetModel.end_time, 'seconds'),
                    durationTime = this.$helpers.getMomentDiff(this.currentTargetModel.start_time, 'seconds', this.currentTargetModel.end_time),
                    elapsedSec = this.$helpers.getMomentDiff(this.currentTargetModel.start_time, 'seconds');
                this.targetTime = currentExist < 0 ? durationTime - elapsedSec : 0;
                if (this.targetTime !== 0) {
                    clearInterval(this.targetTimer);
                    this.startTarget();
                }
            } else {
                this.resetTarget();
            }
        },
        onairTimerUpdate(timerModel) {
            this.onAirTime = timerModel.elapsed;
            if (timerModel.state === 1) {
                const diffElapsed = this.$helpers.getMomentDiff(timerModel.init, 'seconds');
                this.onAirTime = timerModel.elapsed + diffElapsed;
            }
            this.timerState = timerModel.state;
        },
        wsOptActionUpdate() {
            Echo.channel('SHOW_CHALLENGE').listen('.saved',(challenge) => {
                this.model.SHOW_CHALLENGE = challenge.data;
            });
            Echo.channel('SHOW_INCENTIVE').listen('.saved',(incentive) => {
                this.model.SHOW_INCENTIVE = incentive.data;
            });
            Echo.channel('ON_AIR_TIMER').listen('.saved',(onAirModel) => {
                if (onAirModel.data) {
                    this.onairTimerUpdate(onAirModel.data);
                } else {
                    this.timerState = null;
                }
            });
            Echo.channel('incentive').listen('.active',(incentiveModel) => {
                this.incModel = incentiveModel;
            });
            Echo.channel('campaign').listen('.active',(campaignModel) => {
                if(this.producerActCampModel.id === campaignModel.id) {
                    this.$store.commit("setProducerActCampModel", newValue);
                }
            });
        },
        wsTargetUpdate() {
            Echo.channel('target').listen('.saved',(targetData) => {
                const currentTargetModel = this.$helpers.givenTimeRangeCheck(targetData.end_time, targetData.start_time);
                if (currentTargetModel) {
                targetData.deleted_at =  targetData.deleted_at ?
                    globalHelper.getMomentDatas('YYYY-MM-DD hh:mm A', targetData.deleted_at) : null;
                this.$store.commit("setCurrentTargetModel", targetData);
                }
            });
        }
    },
    beforeDestroy() {
        Echo.leaveChannel('ON_AIR_TIMER');
        Echo.leaveChannel('SHOW_CHALLENGE');
        Echo.leaveChannel('SHOW_INCENTIVE');
        Echo.leaveChannel('target');
        Echo.leaveChannel('incentive');
        Echo.leaveChannel('campaign');
        if (this.targetTimer) {
            clearInterval(this.targetTimer);
            this.targetTimer = null;
        }
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
        }
    }
}
</script>